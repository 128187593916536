<template>
  <v-container>
    <h1>Manoeuvres</h1>

    <v-expansion-panels multiple>
      <v-expansion-panel v-for="manoeuvre in manoeuvres" :key="manoeuvre.id">
        <v-expansion-panel-header v-if="manoeuvre.id !== '-1'"> {{ manoeuvre.title }} </v-expansion-panel-header>
        <v-expansion-panel-header v-else>
          <v-skeleton-loader type="heading"></v-skeleton-loader>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="manoeuvre.id !== '-1'">
          <v-row>
            <v-col cols="2">
              <v-btn small fab @click="openEdit(manoeuvre)"><v-icon> mdi-pencil </v-icon></v-btn>
            </v-col>
            <v-col cols="10">
              {{ manoeuvre.description }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
          <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-alert v-if="!manoeuvres.length" type="warning">There are no Manoeuvres yet!</v-alert>
    <v-btn class="mt-4" color="primary" block @click="showNewManoeuvreSheet = true">Define new Test Case</v-btn>

    <v-bottom-sheet v-model="showNewManoeuvreSheet">
      <v-card>
        <v-card-title>Create a new Manoeuvre</v-card-title>
        <v-card-text>
          <manoeuvres-data-form @submit="createManoeuvre">Create</manoeuvres-data-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showEditManoeuvreSheet" v-if="selectedManoeuvre">
      <v-card>
        <v-card-title>Update a Manoeuvre</v-card-title>
        <v-card-text>
          <manoeuvres-data-form :manoeuvre="selectedManoeuvre" @submit="updateManoeuvre">Update</manoeuvres-data-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <app-delete-dialog
      :show="showDeleteManoeuvrerDialog"
      @delete="deleteManoeuvre"
      @close="showDeleteManoeuvrerDialog = false"
    >
      Delete a manoeuvre will also clear all related fields! Are you sure your want delete this car?
    </app-delete-dialog>
  </v-container>
</template>

<script>
import AppDeleteDialog from '@components/app/DeleteDialog'
import ManoeuvresDataForm from '@components/manoeuvres/DataForm.vue'

import { Manoeuvres, CreateManoeuvre, UpdateManoeuvre, DeleteManoeuvre } from '@graphql/Manoeuvres.gql'

export default {
  name: 'ManoeuvresPage',
  components: { AppDeleteDialog, ManoeuvresDataForm },
  apollo: {
    manoeuvres: {
      query: Manoeuvres
    }
  },
  data: () => ({
    showNewManoeuvreSheet: false,
    showEditManoeuvreSheet: false,
    showDeleteManoeuvrerDialog: false,
    selectedManoeuvre: undefined,
    manoeuvres: []
  }),
  methods: {
    openEdit(manoeuvre) {
      this.selectedManoeuvre = manoeuvre
      this.showEditManoeuvreSheet = true
    },
    openDelete(manoeuvre) {
      this.selectedManoeuvre = manoeuvre
      this.showDeleteManoeuvreDialog = true
    },
    createManoeuvre(data) {
      const newManoeuvre = {
        id: '-1',
        ...data
      }
      this.manoeuvres.push(newManoeuvre)
      const variables = { data }
      this.$apollo
        .mutate({ mutation: CreateManoeuvre, variables })
        .then(({ data: { createManoeuvre } }) => {
          newManoeuvre.id = createManoeuvre.id
          this.showNewManoeuvreSheet = false
        })
        .catch((res) => {
          const manoeuvreIdx = this.manoeuvres.findIndex((m) => m.id === '-1')
          this.manoeuvres.splice(manoeuvreIdx, 1)
          this.$store.commit('openErrorDialog', res)
        })
    },
    updateManoeuvre(data) {
      const variables = {
        where: { id: this.selectedManoeuvre.id },
        data: {
          title: { set: data.title },
          description: { set: data.description }
        }
      }
      this.$apollo
        .mutate({ mutation: UpdateManoeuvre, variables })
        .then(({ data: { updateManoeuvre } }) => {
          const manoeuvre = this.manoeuvres.find((manoeuvre) => manoeuvre.id == updateManoeuvre.id)
          Object.assign(manoeuvre, updateManoeuvre)
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
      this.showEditManoeuvreSheet = false
    },
    deleteManoeuvre() {
      // Unused yet!
      const variables = { where: { id: this.selectedManoeuvre.id } }
      this.$apollo
        .mutate({ mutation: DeleteManoeuvre, variables })
        .then(({ data: { deleteManoeuvre } }) => {
          const manoeuvreIdx = this.manoeuvres.findIndex((manoeuvre) => manoeuvre.id == deleteManoeuvre.id)
          this.manoeuvres.splice(manoeuvreIdx, 1)
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
      this.showDeleteManoeuvrerDialog = false
    }
  }
}
</script>
