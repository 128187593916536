<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="local.title" :rules="$rules.required" label="Title" required />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="local.description" :rules="$rules.required" label="Description" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="submit">
            <slot />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'
export default {
  name: 'ManoeuvresDataForm',
  props: {
    manoeuvre: {
      type: Object,
      default() {
        return {
          title: '',
          description: ''
        }
      }
    }
  },
  data: () => ({
    valid: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.manoeuvre)
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      const data = {
        title: this.local.title,
        description: this.local.description
      }
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
